import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Box, Button } from "@mui/material";
import { useAtomValue } from "jotai";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  Currency,
  FreightType,
  ResponseFailureInfo,
} from "@sellernote/_shared/src/types/common/common";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  TrelloBidDetail,
  WithdrawalFormDetail,
  WithdrawalFormListData,
} from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../../../hooks/useSnackbar";
import useGetWithdrawalData from "./hooks/useGetWithdrawalData";
import useHandleWithdrawalInvoice from "./hooks/useHandleWithdrawalInvoice";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../../../jotaiStates/auth";
import CancelWithdrawModal from "./CancelWithdrawModal";
import CurrencyFilter from "./CurrencyFilter";
import PurchaseRequestForm from "./PurchaseRequestForm";
import WithdrawalInvoice from "./WithdrawalInvoice";

const FormSection = ({
  shipmentId,
  freightType,
  companyType,
  companyId,
  exchangeRateList,
  bidAccountPayableId,
  trelloDetailData,
}: {
  shipmentId: number;
  freightType: FreightType;
  companyType: PartnerBusinessArea;
  companyId: number;
  exchangeRateList: ExchangeRate[];
  bidAccountPayableId: number;
  trelloDetailData: TrelloBidDetail;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const userAuth = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const methods = useForm<WithdrawalFormListData>();

  const { watch, reset } = methods;

  const [currency, setCurrency] = useState<Currency>("USD");
  // 직접 입력하거나 갱신 등으로 변경하는 환율 상태값
  const [currentExchangeRate, setCurrentExchangeRate] = useState(0);
  // currentExchangeRate와 비교해 수정 및 갱신이 있었는지 확인하기 위한 환율 상태값
  const [baseExchangeRate, setBaseExchangeRate] = useState(0);
  const [showCancelWithdrawModal, setShowCancelWithdrawModal] = useState(false);

  const {
    withdrawalInvoiceId,
    handleWithdrawalInvoiceIdChange,
    withdrawalInvoiceDate,
    handleWithdrawalInvoiceDateChange,
  } = useHandleWithdrawalInvoice();

  const { isWithdrawalRequested, withdrawalItemList } = useGetWithdrawalData({
    bidAccountPayableId,
    setCurrency,
    setCurrentExchangeRate,
    setBaseExchangeRate,
    handleWithdrawalInvoiceIdChange,
    handleWithdrawalInvoiceDateChange,
    exchangeRateList,
    reset,
  });

  const { mutate: requestWithdrawal, isLoading } =
    TRELLO_BID_QUERY.useRequestWithdrawal();

  const withdrawalFormList = watch("withdrawalFormList");

  const isOnlyKrCurrency = useMemo(() => {
    return withdrawalFormList?.every((v) => {
      return v.currency === "KRW";
    });
  }, [withdrawalFormList]);

  const handleWithdrawalRequestClick = useCallback(
    (requestWithdrawalFlag: boolean) => {
      // 인풋 value 환율이 기준환율과 다르다면 요청할 수 없음
      if (currentExchangeRate !== baseExchangeRate) {
        handleSnackbarOpen("변경된 환율이 적용되지 않았습니다.", "error");
        return;
      }

      const detailList = withdrawalFormList.map((v: WithdrawalFormDetail) => {
        return {
          name: v.name || "",
          currency: v.currency,
          unitPrice: v.unitPrice,
          itemUnitMeasurement: v.itemUnitMeasurement,
          amount: v.amount,
          totalPrice: v.totalPrice,
          vatPrice: v.vatPrice,
          finalPrice: v.finalPrice,
          note: v.note,
          isVAT: v.isVAT,
        };
      });

      //여러개인 각 항목들의 가격들을 합친다.
      const totals = withdrawalFormList.reduce(
        (acc, v) => {
          acc.totalPrice += v.totalPrice;
          acc.finalPrice += v.finalPrice;
          acc.vatPrice += v.vatPrice;
          if (v.currency !== "KRW") {
            acc.foreignFinalPrice += v.unitPrice * v.amount;
          }
          return acc;
        },
        {
          totalPrice: 0,
          finalPrice: 0,
          vatPrice: 0,
          foreignFinalPrice: 0,
        }
      );

      const { totalPrice, finalPrice, vatPrice, foreignFinalPrice } = totals;

      requestWithdrawal(
        {
          detailList,
          bidId: shipmentId,
          companyType,
          requestWithdrawalFlag,
          foreignFinalPrice: isOnlyKrCurrency
            ? 0
            : Number(foreignFinalPrice.toFixed(2)),
          currency: isOnlyKrCurrency ? "KRW" : currency,
          exchangeRate: isOnlyKrCurrency ? 0 : currentExchangeRate,
          totalPrice:
            companyType === "foreign"
              ? Number(finalPrice.toFixed(0))
              : Number(totalPrice.toFixed(0)),
          vatPrice: companyType === "foreign" ? 0 : Number(vatPrice.toFixed(0)),
          finalPrice: Number(finalPrice.toFixed(0)),
          companyId: companyId,
          bidAccountPayableId,
          withdrawalInvoiceId,
          ...(withdrawalInvoiceDate && { withdrawalInvoiceDate }),
        },
        {
          onSuccess: () => {
            handleSnackbarOpen(
              requestWithdrawalFlag
                ? "출금을 요청했습니다."
                : "출금을 저장했습니다."
            );

            handleWithdrawalInvoiceIdChange("");
            handleWithdrawalInvoiceDateChange(null);

            queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.all());
          },

          onError: ({ response }) => {
            const failureInfo = response?.data as ResponseFailureInfo;

            if (failureInfo.errorCode === "E074") {
              handleSnackbarOpen("인보이스를 업데이트 해주세요.", "error");
              return;
            }

            if (failureInfo.errorCode === "E075") {
              handleSnackbarOpen("이미 요청한 상태입니다.", "error");
              return;
            }

            handleSnackbarOpen(
              "에러가 발생했습니다 개발자에게 문의해주세요.",
              "error"
            );
            return;
          },
        }
      );
    },
    [
      baseExchangeRate,
      bidAccountPayableId,
      shipmentId,
      companyId,
      companyType,
      currency,
      currentExchangeRate,
      handleSnackbarOpen,
      handleWithdrawalInvoiceDateChange,
      handleWithdrawalInvoiceIdChange,
      isOnlyKrCurrency,
      queryClient,
      requestWithdrawal,
      withdrawalFormList,
      withdrawalInvoiceDate,
      withdrawalInvoiceId,
    ]
  );

  return (
    <Box width={1000}>
      <FormProvider {...methods}>
        <Box display={"flex"} justifyContent={"space-between"} mt={2}>
          <WithdrawalInvoice
            withdrawalInvoiceDate={withdrawalInvoiceDate}
            onWithdrawalInvoiceDateChange={handleWithdrawalInvoiceDateChange}
            withdrawalInvoiceId={withdrawalInvoiceId}
            onWithdrawalInvoiceIdChange={handleWithdrawalInvoiceIdChange}
          />

          <CurrencyFilter
            currency={currency}
            exchangeRateList={exchangeRateList}
            setCurrency={setCurrency}
            currentExchangeRate={currentExchangeRate}
            setCurrentExchangeRate={setCurrentExchangeRate}
            withdrawalFormList={withdrawalFormList}
            companyType={companyType}
            setBaseExchangeRate={setBaseExchangeRate}
          />
        </Box>

        <PurchaseRequestForm
          withdrawalFormList={withdrawalFormList}
          currency={currency}
          companyType={companyType}
          exchangeRateList={exchangeRateList}
          withdrawalItemList={withdrawalItemList}
          trelloDetailData={trelloDetailData}
          currentExchangeRate={currentExchangeRate}
        />

        <Box display="flex" justifyContent={"center"} mt={2}>
          <Box>
            <Button
              variant="outlined"
              disabled={isWithdrawalRequested || isLoading}
              sx={{ mr: 1 }}
              onClick={() => handleWithdrawalRequestClick(false)}
            >
              저장
            </Button>

            {isWithdrawalRequested ? (
              <Button
                variant="outlined"
                color="error"
                disabled={userAuth?.authority === "finance"}
                onClick={() => setShowCancelWithdrawModal(true)}
              >
                취소 요청
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                disabled={
                  isLoading ||
                  // 해외파트너일 때는 withdrawal 데이터 중 하나라도 입력이 없으면 요청 금지
                  (companyType === "foreign" &&
                    (!withdrawalInvoiceDate || !withdrawalInvoiceId))
                }
                onClick={() => handleWithdrawalRequestClick(true)}
              >
                저장 & 출금요청
              </Button>
            )}
          </Box>
        </Box>

        <CancelWithdrawModal
          isOpen={showCancelWithdrawModal}
          shipmentId={shipmentId}
          partnerCompanyId={companyId}
          setShowCancelWithdrawModal={setShowCancelWithdrawModal}
        />
      </FormProvider>
    </Box>
  );
};

export default FormSection;
