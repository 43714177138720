import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Select,
} from "@mui/material";

import { Currency } from "@sellernote/_shared/src/types/common/common";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  WithdrawalFormDetail,
  WithdrawalFormListData,
} from "@sellernote/_shared/src/types/forwarding/trello";

import TextField from "../../../../../../../../components/TextField";

import useCurrencyFilter from "./useCurrencyFilter";

const CurrencyFilter = ({
  setCurrentExchangeRate,
  currency,
  exchangeRateList,
  setCurrency,
  currentExchangeRate,
  withdrawalFormList,
  companyType,
  setBaseExchangeRate,
}: {
  setCurrentExchangeRate: (value: number) => void;
  currency: Currency;
  exchangeRateList: ExchangeRate[];
  setCurrency: (value: Currency) => void;
  currentExchangeRate: number;
  withdrawalFormList: WithdrawalFormDetail[];
  companyType: PartnerBusinessArea;
  setBaseExchangeRate: (value: number) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { reset } = useFormContext<WithdrawalFormListData>();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const opensPopover = Boolean(anchorEl);

  const {
    handleApplyTodayCurrencyClick,
    handleApplyExchangeRateClick,
    handleCurrencySelectChange,
    handleExchangeRateChange,
    handleApplyCurrencyToAllWithdrawalData,
  } = useCurrencyFilter({
    setCurrentExchangeRate,
    currency,
    exchangeRateList,
    setCurrency,
    currentExchangeRate,
    withdrawalFormList,
    reset,
    companyType,
    setBaseExchangeRate,
  });

  return (
    <Box
      display="flex"
      alignContent={"center"}
      gap={1}
      justifyContent={"flex-end"}
      mb={1}
    >
      <Select
        labelId="currency-select-label"
        value={currency}
        label="통화"
        onChange={(e) => handleCurrencySelectChange(e.target.value as Currency)}
        size="small"
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
        }}
      >
        {exchangeRateList.map((exchange, i) => (
          <MenuItem key={exchange.id} value={exchange.currency}>
            {exchange.currency}
          </MenuItem>
        ))}
      </Select>

      <NumericFormat
        thousandSeparator={true}
        inputProps={{ style: { textAlign: "right" } }}
        decimalScale={2}
        customInput={TextField}
        size="small"
        onValueChange={(value) => {
          handleExchangeRateChange(value?.floatValue || 0);
        }}
        value={currentExchangeRate || ""}
      />

      <Button
        size="small"
        variant="outlined"
        sx={{
          borderStyle: "dashed",
        }}
        onClick={handleApplyExchangeRateClick}
      >
        환율 적용
      </Button>

      <Button
        size="small"
        color="success"
        variant="outlined"
        sx={{
          borderStyle: "dashed",
        }}
        aria-owns={opensPopover ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handleApplyTodayCurrencyClick}
      >
        당일환율로 재계산
      </Button>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={opensPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box padding={1}>
          <List dense={true}>
            {exchangeRateList.map((v: ExchangeRate) => {
              return (
                <ListItem key={v.id}>
                  <ListItemText primary={`${v.currency}: ${v.rate}`} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Popover>

      <Button
        size="small"
        color="secondary"
        variant="outlined"
        sx={{
          borderStyle: "dashed",
        }}
        onClick={() => handleApplyCurrencyToAllWithdrawalData(currency)}
      >
        화폐 일괄적용
      </Button>
    </Box>
  );
};

export default CurrencyFilter;
