import { Button } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

import useSnackbar from "../../../../../../../../../../hooks/useSnackbar";

export default function RequestButton({
  shipmentId,
  isEditMode,
  canSubmit,
  setCanSubmit,
}: {
  shipmentId: number;
  isEditMode: boolean;
  canSubmit: boolean;
  setCanSubmit: (value: boolean) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: requestCargoManifest } =
    TRELLO_BID_QUERY.useRequestCargoManifest({
      shipmentId,
      onSnackbarOpen: handleSnackbarOpen,
    });

  const handleCargoManifestInfoRequest = () => {
    requestCargoManifest(
      {},
      {
        onSuccess: () => {
          handleSnackbarOpen("적하목록을 성공적으로 제출했습니다.");
          setCanSubmit(false);
        },
      }
    );
  };

  return (
    <Button
      sx={{ ml: 1 }}
      variant="contained"
      size="small"
      onClick={handleCargoManifestInfoRequest}
      disabled={isEditMode || !canSubmit}
    >
      제출
    </Button>
  );
}
