import { useCallback, useEffect } from "react";
import { Box } from "@mui/material";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { ForwardingAdminAttachment } from "@sellernote/_shared/src/types/forwarding/trello";

import FileViewer from "../../../../../../../../containers/FileName/FilePreview/FileViewer";
import useFileUrl from "../../../../../../../../hooks/useFileUrl";

export default function Preview({
  attachment,
  s3Url,
  setS3Url,
  imageWidth,
  pdfScale,
  pdfPage,
  fileExtension,
  setFileExtension,
  onPdfSuccessLoad,
}: {
  attachment: ForwardingAdminAttachment;
  s3Url: string;
  setS3Url: (url: string) => void;
  imageWidth: number;
  pdfScale: number;
  pdfPage: number;
  fileExtension: string;
  setFileExtension: (extension: string) => void;
  onPdfSuccessLoad: ({ numPages }: { numPages: number | null }) => void;
}) {
  const { key, name } = attachment;

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const showPreview = useCallback(
    ({ downloadKey }: { downloadKey: string }) => {
      if (!downloadKey) return;

      getFileDownloadUrl(
        { pathParams: { key: downloadKey } },
        { onSuccess: ({ data }) => setS3Url(data.url) }
      );
    },
    [getFileDownloadUrl, setS3Url]
  );

  /**
   * 미리보기가 이벤트가 아닌 렌더링 상태(onMount)에 의존하기 때문에 useEffect 사용
   */
  useEffect(() => {
    if (!key) return;

    showPreview({ downloadKey: key });
    setFileExtension(name.substring(name.lastIndexOf(".") + 1));
  }, [key, name, setFileExtension, showPreview]);

  const { fileUrl, isPdf, isHwp, hwpRenderDivRef } = useFileUrl(
    s3Url,
    fileExtension ?? "pdf"
  );

  return (
    <Box sx={{ mt: 2 }} overflow={"scroll"}>
      <FileViewer
        isHwp={isHwp}
        isPdf={isPdf}
        fileExtension={fileExtension ?? "pdf"}
        imageWidth={imageWidth}
        fileUrl={fileUrl}
        hwpRenderDivRef={hwpRenderDivRef}
        handleDocumentLoadSuccess={onPdfSuccessLoad}
        pdfScale={pdfScale}
        pageNumber={pdfPage}
      />
    </Box>
  );
}
