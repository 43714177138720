import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import { ContainerInfoForAN } from "@sellernote/_shared/src/types/forwarding/trello";

const KEYS_TO_CHECK: (keyof CargoManifestInfoProps)[] = [
  "ETA",
  "carrierCode",
  "mBL",
  "hBL",
  "MRN",
  "callSign",
  "MSN",
  "packageCount",
  "packageSign",
  "totalWeight",
  "totalCBM",
  "itemName",
  "terminalCode",
  "spclCode",
  "assignCode",
  "shipper",
  "shipperAddress",
  "consignee",
  "consigneeAddress",
  "notifyParty",
  "notifyPartyAddress",
  "classification",
  "notifyPartyBRN",
  "consigneeBRN",
];

type UpdatedProperties = Partial<
  Record<
    keyof CargoManifestInfoProps,
    string | number | ContainerInfoForAN[] | null
  >
>;

const checkUpdatedProperties = ({
  form,
  cargoManifestInfo,
}: {
  form: CargoManifestInfoProps;
  cargoManifestInfo: CargoManifestInfoProps;
}) => {
  const updatedProperties = KEYS_TO_CHECK.reduce(
    (acc: UpdatedProperties, key) => {
      if (form[key] !== cargoManifestInfo[key]) {
        acc[key] = form[key];
      }

      return acc;
    },
    {}
  );

  const hasContainer = Boolean(form.containers?.length);

  return {
    ...updatedProperties,
    ...(hasContainer ? { containers: form.containers } : {}),
    ...(hasContainer ? { packageCount: form.packageCount } : {}),
  };
};

export { checkUpdatedProperties };
