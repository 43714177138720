import { useState } from "react";

export default function useHandleWithdrawalInvoice() {
  const [withdrawalInvoiceId, setWithdrawalInvoiceId] = useState<string>("");
  const [withdrawalInvoiceDate, setWithdrawalInvoiceDate] = useState<
    null | string
  >(null);

  const handleWithdrawalInvoiceIdChange = (value: string) => {
    setWithdrawalInvoiceId(value);
  };

  const handleWithdrawalInvoiceDateChange = (date: string | null) => {
    setWithdrawalInvoiceDate(date);
  };

  return {
    withdrawalInvoiceId,
    handleWithdrawalInvoiceIdChange,

    withdrawalInvoiceDate,
    handleWithdrawalInvoiceDateChange,
  };
}
