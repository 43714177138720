import { Box, TextField } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

import {
  CONTAINER_STYLE,
  CUSTOM_TEXT_FIELD_STYLE,
  TITLE_STYLE,
} from "../styles";

export default function AddressForm({
  form,
  onFormChange,
  isEditMode,
  checkHasDataForHighlight,
}: {
  form: CargoManifestInfoProps;
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditMode: boolean;
  checkHasDataForHighlight: (name: keyof CargoManifestInfoProps) =>
    | {
        border: string;
      }
    | {
        border?: undefined;
      };
}) {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>송하인 상호 및 성명</span>

        <TextField
          value={form.shipper}
          variant="outlined"
          size="small"
          sx={{ ...CUSTOM_TEXT_FIELD_STYLE, width: 300 }}
          name="shipper"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>송하인 주소</span>

        <TextField
          value={form.shipperAddress}
          variant="outlined"
          multiline
          rows={3}
          size="small"
          sx={{ ...CUSTOM_TEXT_FIELD_STYLE, width: 300 }}
          name="shipperAddress"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div
        style={{
          ...CONTAINER_STYLE,
          ...checkHasDataForHighlight("consignee"),
        }}
      >
        <span style={TITLE_STYLE}>수하인 상호 및 성명</span>

        <TextField
          value={form.consignee}
          variant="outlined"
          size="small"
          sx={{ ...CUSTOM_TEXT_FIELD_STYLE, width: 300 }}
          name="consignee"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div
        style={{
          ...CONTAINER_STYLE,
          ...checkHasDataForHighlight("consigneeAddress"),
        }}
      >
        <span style={TITLE_STYLE}>수하인 주소</span>

        <TextField
          value={form.consigneeAddress}
          multiline
          rows={3}
          variant="outlined"
          size="small"
          sx={{ ...CUSTOM_TEXT_FIELD_STYLE, width: 300 }}
          name="consigneeAddress"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>수하인 사업자번호</span>

        <TextField
          value={form.consigneeBRN}
          variant="outlined"
          size="small"
          sx={{ ...CUSTOM_TEXT_FIELD_STYLE, width: 300 }}
          disabled={!isEditMode}
          onChange={onFormChange}
          name="consigneeBRN"
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>통지처 상호 및 성명</span>

        <TextField
          value={form.notifyParty}
          variant="outlined"
          size="small"
          sx={{ ...CUSTOM_TEXT_FIELD_STYLE, width: 300 }}
          name="notifyParty"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>통지처 주소</span>

        <TextField
          value={form.notifyPartyAddress}
          multiline
          rows={3}
          variant="outlined"
          size="small"
          sx={{ ...CUSTOM_TEXT_FIELD_STYLE, width: 300 }}
          name="notifyPartyAddress"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>통지처 사업자번호</span>

        <TextField
          value={form.notifyPartyBRN}
          variant="outlined"
          size="small"
          name="notifyPartyBRN"
          sx={{ ...CUSTOM_TEXT_FIELD_STYLE, width: 300 }}
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>
    </Box>
  );
}
