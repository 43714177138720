import React, { useState } from "react";
import { Box, Divider } from "@mui/material";

import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  ForwardingAdminAttachment,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";

import Modal from "../../../../../../components/Modal";

import AttachmentSection from "./AttachmentSection";
import FormSection from "./FormSection";
import Header from "./Header";

const PurchaseRequestModal = ({
  showsPurchaseInvoiceRequestModal,
  setShowsPurchaseInvoiceRequestModal,
  companyType,
  companyId,
  exchangeRateList,
  bidAccountPayableId,
  invoiceType,
  trelloDetailData,
  attachmentList,
}: {
  companyType: PartnerBusinessArea;
  showsPurchaseInvoiceRequestModal: boolean;
  companyId: number;
  setShowsPurchaseInvoiceRequestModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  exchangeRateList: ExchangeRate[];
  bidAccountPayableId: number;
  invoiceType: string;
  trelloDetailData: TrelloBidDetail;
  attachmentList: ForwardingAdminAttachment[];
}) => {
  const [index, setIndex] = useState(0);

  const shipmentId = trelloDetailData.id;

  const freightType = trelloDetailData.freightType;

  return (
    <Modal
      isOpened={showsPurchaseInvoiceRequestModal}
      handleClose={() => {
        setShowsPurchaseInvoiceRequestModal(false);
      }}
      modalBody={
        <Box display="flex" flexDirection={"column"}>
          <Header />

          <Divider />

          <Box display={"flex"} mt={2} gap={2}>
            <AttachmentSection
              key={index}
              shipmentId={shipmentId}
              bidAccountPayableId={bidAccountPayableId}
              invoiceType={invoiceType}
              attachmentList={attachmentList}
              index={index}
              setIndex={setIndex}
            />

            <Divider />

            <FormSection
              shipmentId={shipmentId}
              companyType={companyType}
              freightType={freightType}
              companyId={companyId}
              exchangeRateList={exchangeRateList}
              bidAccountPayableId={bidAccountPayableId}
              trelloDetailData={trelloDetailData}
            />
          </Box>
        </Box>
      }
    />
  );
};

export default PurchaseRequestModal;
